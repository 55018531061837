import React, { PureComponent } from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import { Icon, Button as SemanticButton } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import sc from 'config/theme';
import { Logo } from './ui';
import { SlidingContainer, Media } from 'components/ui';
import { JoinOurTeam, SearchModal, Enquiry, InstantValuation, ReferFriend } from 'components/blocks';
import $ from 'jquery';
import bp from 'config/responsive';

const MobileMenuOverlay = styled.div`
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient( 228deg,#1e3a70 0%,#0e234b 31%,#01153a 67%,#0b101f 100% );
    opacity: 0;
    visibility: hidden;
    z-index: 8;
    -webkit-transition: all 350ms ease-out;
    transition: all 350ms ease-out;

    &.menu-is-open {
      opacity: 0.93;
      visibility: visible;
    }
`;
const MobileMenu = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 8;
    background: #0b1d48;
    background-image: linear-gradient(#234076,#0b1d48);
    padding-top: 70px;
    top: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    transition: transform .6s ease;
    transform: translateX(100%);

    ${bp.computer} {
      width: 40%;
      left: inherit;

      &::before {
        content: '';
        background: #000;
        width: 100%;

      }
    }



  .mobileMenuButtons {
    background: #111d35;
    border-bottom: 1px solid rgba(255,255,255,0.25);

    ${bp.tablet} {
      display: none;
    }

    button {
      width: 33% !important;
      padding: 30px 15px !important;
      border: none !important;

      i {
        font-size: 25px;
      }
      span {
        display: block !important;
        margin-top: 15px;
        text-transform: uppercase;
        font-size: 13px;
      }
    }
  }



  .mobileMenuLinks {
    display: block !important;
    margin: 0;
    padding: 0 25px;

    a {
      font-size: 20px;
      padding: 5.85vw 0;
      border-bottom: 1px solid rgba(255,255,255,0.25);


      ${bp.computer} {
        padding: 30px 0;
      }

      &:last-child {
        border: none;
      }
    }
  }




  &.menu-is-open  {
    transform: translateX(0%);
  }
`;

const HamburgerIcon = styled.div`
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    & .hamburger-line  {
      width: 35%;
      height: 2px;
      background: #fff;
      position: absolute;
      transition: transform .4s ease;

      &.top-line {
        transform: translateY(-8px) rotate(0deg);
        transform-origin: center;
      }
      &.middle-line {
        transform: translateY(0px) scaleX(.85);
        transform-origin: right;
      }
      &.bottom-line {
        transform: translateY(8px) rotate(0deg);
        transform-origin: center;
      }
    }

    &.menu-is-open {
      & .hamburger-line  {

        &.top-line {
          transform: translateY(0px) rotate(45deg);
        }
        &.middle-line {
          transform: translateY(0px) scaleX(0);
        }
        &.bottom-line {
          transform: translateY(0px) rotate(-45deg);
        }
      }
    }


`;


const LoadingStyle = styled('loadingOverlay')`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #202f54;
    z-index: 9;

    opacity: 1;
    visibility: visible;

    display: none;
`;

const Container = styled.nav`
  width: 100%;
  height: 70px;
  position: fixed;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(to right, rgb(17, 28, 52), rgb(32, 47, 84));
  z-index: 20;
  border-bottom: 1px solid rgba(${sc.colors.white_rgb}, 0.25);


  ${bp.tablet} {
    .searchTablet {

      width: 80px !important;
      padding: 0 !important;

      span {
        display: none !important;
      }
    }
  }

  .mobileMenuButtonsIn-nav {
    display: none;

    ${bp.tablet} {
      display: inherit;

      i {
        font-size: 1.3em;
      }
    }

    ${bp.computer} {
      i {
        font-size: 1em;
      }
    }
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  a {
    font-size: 14px;
    color: ${sc.colors.white};
  }
`;

const LogoContainer = styled(FlexContainer)`
  width: 100%;
  max-width: 190px;
  height: 100%;
  margin-left: 25px;
  margin-right: 25px;
  align-items: center;

  a {
    flex: 1;
  }

  ${bp.computer} {
    margin-right: 75px;
    max-width: 230px;
  }
`;

const CallsToActionContainer = styled.div`
  display: flex;
`;

const LinksContainer = styled(FlexContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 36px;
`;

const ActionsContainer = styled(FlexContainer)`
  display: flex;
  color: ${sc.colors.white};

  .hamburgerIcon {
    max-width: 80px;
    padding: 0 15px !important;
    border: none !important;


  }
`;

const Action = styled(SemanticButton)`
  &&& {
    background: transparent;
    border-radius: 0;
    margin: 0;
    color: ${sc.colors.white};
    border-left: 1px solid rgba(${sc.colors.white_rgb}, 0.25);
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    letter-spacing: 0.88px;
    transition: 0.4s ease;
    position: relative;

    width: 80px;
    padding: 0 15px;

    ${bp.tablet} {
      border-left: 1px solid rgba(255,255,255,0.25) !important;
    }

    ${bp.computer} {
      width: inherit;
      padding: 0 40px;
      width: 196px;

    }


    ${({ search }) =>
      search &&
      css`
        padding: 0;
        width: 80px;

        &.search-btn {
          padding: 0 !important;
          width: 80px!important;
        }
      `}

    i {
      display: unset;
    }

    &:hover {
      background-color: rgba(${sc.colors.white_rgb}, 0.1);
    }

    &::after {
      transition: 0.4s ease;
      content: '';
      display: block;
      position: absolute;
      height: 1px;
      left: -1px; /* negating border */
      right: 0;
      top: 100%;
      background: ${sc.colors.white};
      opacity: 0;
    }

    ${({ active }) =>
      active &&
      css`
        background-color: ${sc.colors.white};
        color: ${sc.colors.main};

        &::after {
          opacity: 1;
        }

        &:hover {
          background-color: ${sc.colors.white};
        }
      `}

      span {
        display: none;

        ${bp.computer} {
          display: inline-block;
        }
      }

      i {
        margin: 0 !important;

        ${bp.computer} {
          margin: 0 .42857143em 0 -.21428571em !important;
        }
      }
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  padding: 0 20px;
  font-weight: 500;
  letter-spacing: 0.88px;

  span {
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
  }
`;

const GoldenLine = styled.div`
  display: block;
  height: 5px;
  position: absolute;
  top: 100%;
  margin-left: 20px;
  margin-top: -2.5px;
  background-color: ${sc.colors.yellow};

  &.animated {
    transition: all 0.25s ease-out;
  }
`;

const NavLink = ({ children, ...props }) => (
  <StyledLink {...props} className="navlink" activeClassName="active">
    {children}
  </StyledLink>
);

class NavbarEmpty extends PureComponent {
  constructor(props) {
    super(props);

    this.toggleInstantValuation = () => {
      this.toggleModal('instantValuationOpen');
    }
    this.toggleEnquiry = () => {
      this.toggleModal('enquiryOpen');
    }
    this.toggleJoinOurTeam = () => {
      this.toggleModal('joinOurTeamOpen');
    }
    this.toggleReferFriend = () => {
      this.toggleModal('referFriendOpen');
    }

    if (typeof window !== 'undefined') {
      window.openInstantValuation = () => this.toggleModal('instantValuationOpen');
      window.openEnquiry = () => this.toggleModal('enquiryOpen');
      window.openJoinOurTeam = () => this.toggleModal('joinOurTeamOpen');
      window.openReferFriend = () => this.toggleModal('referFriendOpen');
    }
  }

  state = {
    instantValuationOpen: false,
    enquiryOpen: false,
    searchOpen: false,
    joinOurTeamOpen: false,
    referFriendOpen: false,
    isMenuOpen: false,
  };

  componentDidMount() {
    this.setGoldenLine(true);
    window.addEventListener('resize', this.setGoldenLine);
    window.addEventListener('DOMContentLoaded', this.setGoldenLine);
    setTimeout(() => this.setGoldenLine(true), 1000); // Fallback :)
  }

  componentWillUnmount() {
    window.addEventListener('resize', this.setGoldenLine);
  }

  setGoldenLine = (initialCreation, target = '.navlink.active') => {
    let $target = $(target);

    if (!$target.length) {
      $target = $('.navlink.fallback-active');
    }

    if (!$target || !$target.length) {
      return false;
    }

    const width = $target.width();
    const left = $target.offset().left;
    const $goldenLine = $('#golden-line');

    if (!$goldenLine.length) {
      return false;
    }

    // Stop animation when "creating"
    if (initialCreation) {
      $goldenLine.removeClass('animated');
    }

    // Re-position
    $goldenLine.css({ left, width })

    // Start animations at this point
    setTimeout(() => $goldenLine.addClass('animated'), 2);
  }

  handleLinkMouseEnter = (event) => {
    let target;

    if (event.target.classList.contains('navlink')) {
      target = event.target;
    } else {
      target = event.target.parentNode;
    }

    this.setGoldenLine(false, target);
  }

  handleLinkMouseLeave = () => {
    this.setGoldenLine(false);
  }

  toggleModal = stateKey => {
    const currentState = this.state[stateKey];
    const nextState = !currentState;

    if (nextState) {
      window.closeModal && window.closeModal();
      window.closeModal = () => this.toggleModal(stateKey, false);
    } else {
      window.closeModal = undefined;
    }

    const newState = {};
    newState[stateKey] = nextState;

    this.setState(newState);
  };

  toggleSearch = () => this.toggleModal('searchOpen');

  handleHamburgerToggle = () => this.setState(({ isMenuOpen }) => ({ isMenuOpen: !isMenuOpen }));

  render() {
    const { isMenuOpen, instantValuationOpen, enquiryOpen, searchOpen, joinOurTeamOpen, referFriendOpen } = this.state;

    return (
      <StaticQuery
        query={graphql`
          query FooterNotesEmpty {
            trustSpotImg: file(relativePath: { eq: "trust-spot.png" }) {
              childImageSharp {
                fixed(width: 156, height: 35) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            propertyImg: file(
              relativePath: { eq: "the-property-ombudsman.png" }
            ) {
              childImageSharp {
                fixed(width: 118, height: 35) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            pages {
              navbar {
                links {
                  route
                  name
                }
                valuation
                enquiry
              }
            }
          }
        `}
        render={data => {
          const {
            pages: { navbar },
          } = data;
          const { links, valuation, enquiry } = navbar;

          return (
            <Media
              breakpoint={bp.large}
              renderIfTrue={() => (
                <React.Fragment>                  
                  <Container>
                    <LogoContainer>
                      <Link to="/">
                        <Logo />
                      </Link>
                    </LogoContainer>
                    
                  </Container>
                </React.Fragment>
              )}
              renderIfFalse={() => (
                <React.Fragment>                  
                  <Container>
                    <LogoContainer>
                      <Link to="/">
                        <Logo />
                      </Link>
                    </LogoContainer>
                    
                  </Container>
                </React.Fragment>
              )}
            />
          );
        }}
      />
    );
  }
}

export default NavbarEmpty;

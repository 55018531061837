/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { PureComponent } from 'react';
import { StaticQuery, graphql, navigate } from 'gatsby';
import styled from 'styled-components';
import sc from '../config/theme';
import NavbarEmpty from './NavbarEmpty';
import Footer from './Footer';
import { createGlobalStyle } from 'styled-components';
import { FormContext, form } from 'context/propertySearch';
import bp from 'config/responsive';

const Content = styled.div`
  background: ${sc.colors.main};
  overflow: hidden;
  
  ${bp.large} {
    overflow: inherit;
  }
`;

const StyledSelection = createGlobalStyle`
  ::-moz-selection {
    background: rgba(${sc.colors.yellow_rgb}, 0.5);
    color: ${sc.colors.main};
    text-shadow: none;
  }
  ::selection {
    background: rgba(${sc.colors.yellow_rgb}, 0.5);
    color: ${sc.colors.main};
    text-shadow: none;
  }
`;

const Main = styled.div``;

class LayoutEmpty extends PureComponent {
  constructor(props) {
    super(props);

    this.handleChange = ({ target: { name, value } }) => {
      this.setState(({ form }) => ({
        form: {
          ...form,
          [name]: value,
        },
      }));
    };

    this.handleSearch = () => {

      let str = '';
      const { form } = this.state;

      Object.keys(form).forEach((key, index, arr) => {
        if (index >= arr.length - 1) {
          str += `${key}=${form[key]}`;
        } else {
          str += `${key}=${form[key]}&`;
        }
      });

      navigate(`search-results?${str}`);
    };

    this.handleSemanticChange = (_, { name, value, checked }) => {
      if (name == 'includeNewHomes'){
        if (checked){
          this.setState(({ form }) => ({
            form: {
              ...form,
              more: ['1-option'],
            },
          }));

        }else{

          this.setState(({ form }) => ({
            form: {
              ...form,
              more: [],
            },
          }));

        }
      }

      this.setState(({ form }) => ({
        form: {
          ...form,
          [name]: value || checked,
        },
      }));
    };

    this.setArea= (area) => {
      console.log('setting area')

      this.setState(({ form }) => ({
        form: {
          ...form,
          area: area,
        },
      }));
    }

    this.toggleSort = () => {
      var neworder = (this.state.form.sortOrder === "DESC" ? "ASC" : "DESC");
      this.setState(({ form }) => ({
        form: {
          ...form,
          sortBy: 'Price',
          sortOrder: neworder,
        },
      }));
 
      let str = '';
      const { form } = this.state;
        Object.keys(form).forEach((key, index, arr) => {
          if (index >= arr.length - 1) {
            str += `${key}=${form[key]}`;
          } else {
            str += `${key}=${form[key]}&`;
          }
        });

      navigate(`search-results?${str}`);

    };

    this.handleRangeChange = ({ min, max }) => {
      this.setState(({ form }) => ({
        form: {
          ...form,
          priceMin: min,
          priceMax: max,
        },
      }));
    };

    this.directStateMutate = (newForm) => {
      this.setState({
        form: {
          ...this.state.form,
          ...newForm,
        },
      });
    }

    let savedState = {};
    if (typeof window !== 'undefined') {
      window.directStateMutate = this.directStateMutate;
      savedState = window.savedState || {};
    }

    this.state = {
      form: {
        ...form,
        ...savedState,
      },
      directStateMutate: this.directStateMutate,
      handleChange: this.handleChange,
      handleSearch: this.handleSearch,
      toggleSort: this.toggleSort,
      setArea: this.setArea,
      setMore: this.setMore,

      handleSemanticChange: this.handleSemanticChange,
      handleRangeChange: this.handleRangeChange,
    };
  }
  componentDidMount() {
    const { onVisible } = this.props;

    onVisible && onVisible();
  }

  renderContent = data => {
    const { children, noFooter } = this.props;

    return (
      <Content>
        <StyledSelection />
        <FormContext.Provider value={this.state}>
          <NavbarEmpty siteTitle={data.site.siteMetadata.title} />
          <Main>{children}</Main>
        </FormContext.Provider>
      </Content>
    );
  };

  render() {
    if (typeof window !== 'undefined') {
      window.savedState = this.state.form;
    }

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleValQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={this.renderContent}
      />
    );
  }
}

export default LayoutEmpty;

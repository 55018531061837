import React from "react"
import styled from 'styled-components';
import LayoutEmpty from "../components/layoutEmpty"
import SEO from "../components/seo"
import {
  InnerContainer,
  Spacer,
  Column,
  Grid,
} from 'components/ui';
import sc from 'config/theme';
import $ from 'jquery';
import { Script, Sans, Serif } from 'Typography';
import bp from 'config/responsive';
import { InstantValuation } from 'components/blocks';

import bgImage from 'images/valuation-bg.jpg';

const Container = styled.div`
  background: ${sc.colors.white};
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;

  &.bg-image {
    min-height: 100vh;
    background-image: url(${({ src }) => src});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;


    .white-box {
      padding: 40px 20px;
      background: #fff;

      ${bp.tablet} {
        padding: 40px 60px;
      }
    }
    form > span {
      color: white !important;
    }
    
  }

  ${bp.computer} {
    padding-top: 200px;
    padding-bottom: 100px;
    min-height: 70vh;
  }

  & .valuationMaxWidth {
    max-width: 800px;
    margin: auto;
  }

`

const StyledGrid = styled(Grid)`
  &&& {
    padding-top: 50px;
    margin-bottom: 30px;

    ${bp.tablet} {
      margin-bottom: 100px;
    }

    ${bp.computer} {
      padding-top: 0;
      margin-bottom: 100px;
    }
  }
`;

const Title = styled.div`
  margin-bottom: 20px;

  ${bp.computer} {
    margin-bottom: 20px;
  }
`;

const Paragraph = styled.p`
  display: block;

`;



const ValuationPage = () => (

  <LayoutEmpty>
    <SEO title="Instant Valuation" description="Fill in the form below to find out what your property is worth."/>
    <Container className="bg-image" src={bgImage}> 
    {/* className="bg-image" src={bgImage} */}
      <InnerContainer>
        <StyledGrid>
          <Spacer />
          <Column width={10}>
            <div className="valuationMaxWidth">
              <InstantValuation  displaycontainer={true}/>
            </div>
          </Column>
          <Spacer />
        </StyledGrid>

      </InnerContainer>
    </Container>

  </LayoutEmpty>
)

export default ValuationPage
